.form {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    padding: 20px;
    background: #f7f7f75e;
    box-shadow: 0 0 0 0.5px rgb(195, 195, 195);
    border-radius: 5px;
    h3 {
        margin: 0 0 10px 0;
        font-weight: bold;
    }

    .form-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 10px 0;

        &__element {
            display: flex;
            flex-direction: column;
            width: 48%;
            margin-bottom: 10px;

            label {
                font-weight: bold;
                margin-bottom: 5px;
                font-size: 14px;
            }

            input {
                padding: 5px;
                border-radius: 5px;
                border: 1px solid #ccc;
                font-size: 16px;
                height: 30px
            }

            select {
                padding: 5px;
                border-radius: 5px;
                border: 1px solid #ccc;
                font-size: 16px;
                height: 40px !important
            }
        }
    }

    button[type="submit"] {
        align-self: flex-start;
        margin-top: 10px;
        height: 50px;
        width: 250px;
        font-size: 16px;
        background-color: #28cf93;
        border: none;
        outline: none;
        color: white;
    }
}