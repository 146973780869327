.main {
  display: flex;

  .container {
    flex: 6;

    .content {
      padding: 10px;

      .heading{
        margin-top:10px;
      }

      .widgets {
        display: flex;
        padding: 20px;
        gap: 20px;
      }
    }

  }
}