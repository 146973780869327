.main {
    display: flex;

    .container {
        flex: 6;

        .content {
            padding: 10px;
        }

    }
}