.login-page {
    display: flex;
    height: 100vh;
    flex-direction: row-reverse;
    background-color: rgba(242, 247, 246, 0.8117647059);
    //topbar

    //Left column
    .left-column {
        position: relative;
        flex: 1.5;
        display: flex;
        align-items: center;
        justify-content: center;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 50px 0 0 50px;
        box-shadow: -4px 0px 6px 0px rgba(0, 0, 0, 0.52);
        -webkit-box-shadow: -4px 0px 6px 0px rgba(0, 0, 0, 0.52);
        -moz-box-shadow: -4px 0px 6px 0px rgba(0, 0, 0, 0.52);
        transition: 0.5s ease;


        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 50px 0 0 50px;
            background: linear-gradient(to bottom, #00AA9Dac, #18344cd2);
        }

        .content {
            position: relative;
            z-index: 1;
            text-align: center;
            color: #fff;



            h1 {
                font-size: 2rem;
                margin-bottom: 1rem;
                text-transform: capitalize;
                transition: 0.5s ease;
            }

            p {
                font-size: 1rem;
                margin-bottom: 1.5rem;
                transition: 0.5s ease;
                
            }

            .sign-up-button {
                background-color: #66fff296;
                color: #ffffff;
                padding: 1rem;
                min-width: 250px;
                border-radius: 50px;
                font-size: 1.2rem;
                cursor: pointer;
                transition: background-color 0.5s ease;
                border: none;
                width: 50%;

                &:hover {
                    background-color: #09d5c4bc;
                    color: #FFF
                }
            }

        }

        &:hover {
            flex: 2;
            //transition: 1s ease;
        }
    }


    //right column
    .right-column {
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        background-color: #f2f7f6cf;

        img {
            width: 250px;
        }

        h1 {
            font-size: 2rem;
            margin-bottom: 1rem;
            font-weight: 700;
            color: #59595B;
        }

        .social-buttons {
            display: flex;

            align-items: center;
            justify-content: center;
            margin-bottom: 1rem;

            img {
                margin-right: 1rem;
                width: 25px;

            }

            p {
                display: flex;
                margin: 0 1rem;
                position: relative;

                &:before, &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    width: 100%;
                    height: 1px;
                    background-color: black;
                    margin-top: -0.5px;
                  }
                  &:before {
                    left: 0;
                  }
                  &:after {
                    right: 0;
                  }
            
            }
        }

        form {
            display: flex;
            width: 50%;
            flex-direction: column;
            align-items: center;

            input {
                min-width: 350px;
                margin-bottom: 1rem;
                padding: 1rem;
                font-size: 1rem;
                border-radius: 3px;
                border: 1px solid #ccc;
            }

            .button {
                min-width: 250px;
                padding: 1rem;
                background-color: #00AA9D;
                color: #fff;
                font-size: 1rem;
                border: none;
                border-radius: 50px;
                cursor: pointer;
                transition: background-color 0.2s;
                text-align: center;
                text-decoration: none;

                &:hover {
                    background-color: #008b81;
                }
            }

            div {
                margin-top: 1rem;
                text-align: center;

                a {
                    color: #00AA9D;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }


}