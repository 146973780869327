.navbar {
    height: 60px;
    box-shadow: 0 0 0 0.5px #f7f7f7;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #555;
    background-color: #f7f7f7;
  
    .wrapper {
      width: 100%;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
  
     
      .items {
        display: flex;
        align-items: center;
  
        .item {
          display: flex;
          align-items: center;
          margin-right: 20px;
          position:relative;
          cursor:pointer;
  
          .icon {
            font-size: 20px;
          }
  
          .avatar{
              width: 30px;
              height: 30px;
              border-radius: 50%;
          }
  
          .counter{
              width: 15px;
              height: 15px;
              background-color:red;
              border-radius:50%;
              color:white;
              display:flex;
              align-items: center;
              justify-content: center;
              font-size: 10px;
              font-weight:bold;
              position:absolute;
              top:-5px;
              right:-5px;
          }
        }
      }
    }
  }
  